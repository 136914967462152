#notification-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  bottom: 20px;
  width: 100%;
  height: 80px;
  color: white;
  text-align: center;
  z-index: 99999;
}

#notification-bar #notification-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: red;
  width: 85%;
  height: 100%;
  padding: 0 10px;
  border-radius: 10px;
}
/*# sourceMappingURL=NotificationBar.css.map */