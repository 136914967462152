#navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 70px;
  text-align: center;
}

#navbar .inner {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4];
      grid-template-columns: repeat(4, 1fr);
  background-color: #495057;
  border-radius: 0 0 20px 20px;
  width: 80%;
  height: 100%;
}

#navbar .inner a {
  text-decoration: none;
  color: white;
}

#navbar .inner a .menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
}

#navbar .inner a .menu:hover {
  background-color: #6c757d;
  border-radius: 0 0 20px 20px;
}

@media only screen and (max-width: 599px) {
  #navbar .inner {
    width: 95%;
  }
}
/*# sourceMappingURL=navbar.css.map */