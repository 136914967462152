body {
  margin: auto 0;
  color: #cccccc;
  background-color: black;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#home-page {
  margin: 10px 0;
}

#home-page .main-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
}

#home-page .main-div .skrin-info {
  width: 80%;
  font-size: 1.5rem;
  text-align: center;
}

#home-page .main-div #video-display {
  width: 500px;
  height: 300px;
  border: solid 5px gray;
  border-radius: 15px;
}

#home-page .main-div .text-message {
  text-align: center;
  font-size: 2rem;
  margin: 10px 0;
}

#home-page .controller-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.808);
  border-top: solid 1px gray;
}

#home-page .controller-div .buttons {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[5];
      grid-template-columns: repeat(5, 1fr);
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  padding: 5px;
  width: 800px;
  margin-top: 10px;
}

#home-page .controller-div .buttons select {
  background-color: #343A40;
  color: white;
  font-size: 1rem;
  height: 100%;
  width: 100%;
  border: dashed 1px white;
  border-radius: 20px;
  outline: none;
}

#home-page .controller-div .buttons select:hover {
  cursor: pointer;
  background-color: black;
}

#home-page .controller-div .buttons button {
  background-color: #cecece;
  font-size: 1rem;
  border: none;
  border-radius: 20px;
  outline: none;
}

#home-page .controller-div .buttons button:hover {
  cursor: pointer;
  background-color: white;
}

#home-page .controller-div .buttons button:active {
  border: green;
}

@media only screen and (max-width: 599px) {
  #home-page img {
    width: 95%;
  }
}
/*# sourceMappingURL=home.css.map */