body:hover::-webkit-scrollbar-thumb {
  background: #4a4a4a;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background: #4a4a4a;
}
/*# sourceMappingURL=App.css.map */