#transcode-page {
  margin: 10px 0;
}

#transcode-page button {
  background-color: #343a40;
  color: white;
  font-size: 1rem;
  height: 80%;
  width: 30%;
  margin: 5px;
  border: dashed 1px white;
  border-radius: 20px;
  outline: none;
}

#transcode-page button:hover {
  cursor: pointer;
  background-color: black;
}

#transcode-page .video-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#transcode-page .video-div .video-display {
  width: 400px;
  height: 250px;
  border: solid 5px gray;
  border-radius: 15px;
}

#transcode-page .video-div #video-input {
  left: 0;
}

#transcode-page .video-div #video-output {
  right: 0;
}

#transcode-page .video-div .button {
  width: 100px;
  height: 50px;
}

#transcode-page .video-div .button:hover {
  cursor: pointer;
}

#transcode-page #progress-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 1rem;
}

#transcode-page #progress-div #progress-bar {
  width: 50%;
  height: 1.5rem;
}

#transcode-page .drop-zone {
  width: 100%;
  height: 300px;
  outline: dashed gray;
  text-align: center;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  margin-bottom: 100px;
}

#transcode-page .drop-zone:hover {
  cursor: pointer;
  background-color: gray;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

#transcode-page #option-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.808);
  border-top: solid 1px gray;
}
/*# sourceMappingURL=transcode.css.map */